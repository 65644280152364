import logo from './logo.png';
import hourglass from './hourglass_icon.png';
import './App.css';
import React, { useState } from 'react';
import axios from 'axios';

function App() {
  
  const [textInput, setTextInput] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const sanitizedInput = encodeURIComponent(textInput);
      //const response = await axios.get(`https://vdo74xumkb.execute-api.us-east-1.amazonaws.com/dev/text2text?prompt=${sanitizedInput}`); //https://0xn432b5o5.execute-api.us-east-1.amazonaws.com/dev
      const response = await axios.get(`https://0xn432b5o5.execute-api.us-east-1.amazonaws.com/dev/text2text?prompt=${sanitizedInput}`);
      const outputText = response.data || '';
      setApiResponse(outputText);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  
  const handleKeyDown = event => {
    if (event.keyCode === 13) { // Enter key
      handleClick();
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          asanode's<br /><code>Generative AI text2text <br />application test</code>
        </p>
      
      <textarea
        value={textInput}
        onChange={event => setTextInput(event.target.value)}
        onKeyDown={handleKeyDown}
        rows="4"
        cols="50"
        placeholder="Enter text to be completed here..."
      />
      <br />
      
      <button onClick={handleClick}>Generate Text using Anthropic Claude v2 model</button> 
      {isLoading && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0px' }}>
          <p>Loading...</p>
          <img src={hourglass} alt="Loading..." width="50px" height="50px" />
        </div>
      )}
      
      {apiResponse && (
        <div className="App-text" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px', overflow: 'auto' }}>
          <p style={{ whiteSpace: 'pre-wrap' }}>{apiResponse}</p>
          
        </div>
      )}
      
      </header>
      
      <div style={{ backgroundColor: 'black', color: 'white', marginTop: '2px', textAlign: 'center', padding: '2px' }}>
        This app is for demonstration purposes only. Users are responsible for the outputs they generate. <br />
        Model: txt2txt-anthropic-claude-v2. Generated content may be inaccurate or false.
      </div>
    </div>
  );
  
}

export default App;
